/* KVKKPage.css */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

.kvkk-page {
    font-family: 'Merriweather', serif;
    line-height: 1.6;
    color: #333;
}

.kvkk-page h2, .kvkk-page h4 {
    font-weight: 700;
    margin-bottom: 1rem;
}
