/* App.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, h1, h2, h3, h4, h5, h6, p, a, button, span, nav, table, li, ul, td, tr, th, label, div {
  font-family: 'Roboto', sans-serif;
}

.btn, .navbar, .card {
  font-family: 'Roboto', sans-serif;
}

.bg-soft {
    background-color: rgba(248, 249, 250, 0.9);
}

.footer-link:hover {
    color: #006400;
    text-decoration: underline;
}
