.my-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; /* Carousel item yüksekliği */
  padding: 0 10px; /* Görselin kenarlara yapışmasını engellemek için yatay padding */
}

.carousel-img {
  max-width: 100%;
  max-height: 60vh;
  object-fit: contain; /* Görsellerin boyutunu aşırı bozmadan yerleştirir */
}

@media (max-width: 768px) {
  .my-carousel-item {
    height: 40vh; /* Mobilde carousel item yüksekliği */
  }

  .carousel-img {
    max-width: 100%;
    max-height: 40vh;
  }
}

/* Carousel text container */
.carousel-caption {
    background-color: rgba(85, 107, 47, 0.8); /* Dark Olive Green arka plan, %80 opaklık */
    padding: 20px;
    border-radius: 5px;
    text-align: center; /* Metni ortalar */
    max-width: 90%;
    width: auto;
    margin-bottom: 15px; /* Görsel ile metin arasında daha fazla boşluk sağlar */
}

@media (max-width: 768px) {
  .carousel-caption {
      padding: 10px; /* Mobilde daha az padding */
      margin-bottom: 10px;
  }
}

/* Carousel title */
.carousel-caption h3 {
    color: #FFD700; /* Altın sarısı başlık rengi */
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.2; /* Başlık metni sıkışırsa daha düzgün görünmesini sağlar */
}

/* Carousel description */
.carousel-caption h5 {
    color: #F5F5DC; /* Bej açıklama rengi */
    font-family: 'Roboto', sans-serif;
    margin-top: 5px;
    line-height: 1.3; /* Açıklama metninin okunabilirliğini artırır */
}
