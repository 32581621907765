.item {
    max-height: 95vh;
    min-height: 75vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.item-card-text {
    max-height: 100%;
    overflow: auto;
    position: relative;
}

.item-p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Number of lines to show before ellipsis */
    -webkit-box-orient: vertical;
    white-space: normal;
    margin: 0;
    padding: 0;
}

.item-hover-p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
}

.item-img {
    max-height: 40vh;
    max-width: 100vw;
}

.item-hover-img {
    max-height: 60vh;
    max-width: 100%;
}

