/* Animation */
@keyframes slide-up-animation {
  from {
    transform: translateY(50%); /* Only move partially off-screen */
    opacity: 0; /* Hidden initially */
  }
  to {
    transform: translateY(0); /* Move to normal position */
    opacity: 1; /* Fully visible */
  }
}

.slide-up {
  opacity: 0; /* Initially hidden */
  transform: translateY(50%); /* Only move 50% off the viewport */
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

.slide-up.show {
  transform: translateY(0); /* Move to normal position */
  opacity: 1; /* Make the div fully visible */
}