.video-placeholder {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-placeholder .play-button {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
}

.video-placeholder .play-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.video-card-text {
    max-height: 100%;
    overflow: auto;
    position: relative;
}

.video-p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Number of lines to show before ellipsis */
    -webkit-box-orient: vertical;
    white-space: normal;
}

.video-p-loaded {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Number of lines to show before ellipsis */
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    margin: 0;
    padding: 0;
}

.video-card {
    max-height: 95vh;
    min-height: 75vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: stretch; /* Ensures the card stretches to the tallest column */
}

.item-video {
    max-width: 100%;
    max-height: 70%;
}

.item-reels {
    max-height: 80%;
    max-width: 100%;
}
